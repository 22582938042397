import { useState } from 'react';
import logogaleria1 from '../assets/Images/galeria1.jpg';
import HeaderComplementario from './HeaderComplementario';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';
import Footer from './Footer';
import axios from 'axios';
import { objectToFormData } from '../utils';

const Contacto = () => {
  const [recaptchavalue, setRecaptchaValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [fields, setFields] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    message: '',
  });

  const handleChangeField = (field, value) => {
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const submitDisabled = () => {
    if (loading) return true;

    const formFields = Object.keys(fields);
    let empty = false;

    for (let field of formFields) {
      const value = fields[field].trim();
      if (value === '') {
        empty = true;
        break;
      }
    }

    return empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/mail.php`,
        data: objectToFormData({
          nombre: fields.name,
          correo: fields.email,
          telefono: fields.phone,
          ciudad: fields.city,
          mensaje: fields.message,
          'g-recaptcha-response': recaptchavalue,
        }),
      });

      if (response.data.status !== 'ok') throw new Error();

      Swal.fire({
        title: 'Mensaje enviado!',
        text: 'Se ha enviado correctamente!',
        icon: 'succes',
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Lo sentimos, se ha producido un error, intente nuevamente.');
    }
  };

  return (
    <>
      <HeaderComplementario />

      <div className='container-contacto'>
        <h1 className=' presupuesto-detail wrapper'>Pedí tu presupuesto</h1>
        <div className='container-detail wrapper'>
          <form className='form-container' onSubmit={handleSubmit}>
            <div className='form-group'>
              <label>Nombre</label>
              <input
                type='text'
                value={fields.name}
                onChange={(e) => handleChangeField('name', e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>Email</label>
              <input
                type='email'
                value={fields.email}
                onChange={(e) => handleChangeField('email', e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>Telefono</label>
              <input
                type='text'
                value={fields.phone}
                onChange={(e) => handleChangeField('phone', e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>Ciudad</label>
              <input
                type='text'
                value={fields.city}
                onChange={(e) => handleChangeField('city', e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>Mensaje</label>
              <textarea
                name='menssage'
                cols='30'
                rows='10'
                type='text'
                value={fields.message}
                onChange={(e) => handleChangeField('message', e.target.value)}
              ></textarea>
            </div>

            {error && <p>{error}</p>}

            <ReCAPTCHA
              className='recaptcha'
              sitekey='6Lem3mUUAAAAACbAoDCWjcGYcU99wPBRbDwUyptS'
              onChange={setRecaptchaValue}
            />

            <button className='form-button' disabled={submitDisabled()}>
              ENVIAR
            </button>
          </form>
          <div className='container-img-contact'>
            <img src={logogaleria1} alt='' />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contacto;
