import React, { Component } from "react";
import Slider from "react-slick";
import cerco1 from "../assets/Images/1.jpeg";
import cerco2 from "../assets/Images/2.jpeg";
import cerco3 from "../assets/Images/4.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
    };

    return (
      <div className="container-carrousel-slider">
        <div className="slider-container">
          <Slider {...settings}>
            <div className=" wrapper">
              <img className="slider-img" src={cerco1} alt="" />
            </div>
            <div className="wrapper">
              <img className="slider-img" src={cerco2} alt="" />
            </div>
            <div className="wrapper">
              <img className="slider-img" src={cerco3} alt="" />
            </div>
          </Slider>
        </div>

        <p className="text">
          seguridad perimetral <br /> para tu tranquilidad.
        </p>
      </div>
    );
  }
}
