import axios from "axios";

const BASE_URL = "https://cercoelectricosecurity.com";

export const fetchGalleries = async () => {
  try {
    const response = await axios({
      url: `${BASE_URL}/get-galleries.php`,
    });

    return response.data;
  } catch (err) {
    return [];
  }
};
