import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Presupuesto from "./components/Contacto";
import Home from "./components/Home";
import Nosotros from "./components/Nosotros";
import ComoTrabajamos from "./components/ComoTrabajamos";
import GaleriaListContainer from "./components/GaleriaListContainer";
import Galeria from "./components/Galeria";
import VideoSeguridad from "./components/VideoSeguridad";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/presupuesto" element={<Presupuesto />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/comotrabajamos" element={<ComoTrabajamos />} />
          <Route path="/galeria" element={<GaleriaListContainer />} />
          <Route path="/galeria:id" element={<Galeria />} />
          <Route path="/videoseguridad" element={<VideoSeguridad />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
