import wpLogo from "../assets/Images/whatsapp-xxl.webp";
import Icon from "./Icon";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/Images/cerco-sequrity-logo.png";
import HamburguerMenu from "./HamburguerMenu";
import NavBar from "./NavBar";
import { useState } from "react";

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);

    document.body.classList.toggle("body-fijo", !open);
  };

  return (
    <>
      <div className="container-header  wrapper ">
        <div className="container-top-header">
          <div className="whatsapp-detail">
            <div className="separacion"></div>
            <div className="container-wsp-phone">
              <a href="https://api.whatsapp.com/send?phone=541121772352">
                {" "}
                <img src={wpLogo} alt="logo-wsp" />
              </a>

              <Icon css="icon" icon={faPhone} />
              <a className="tel-header" href="tel:1121772352">
                {" "}
                011 2177-2352
              </a>
            </div>

            <div>
              <p className="horario">Lunes a Sábados de 9:00 a 21:00 hs </p>
            </div>
          </div>
        </div>

        <div className="container-bottom-header wrapper">
          <HamburguerMenu handleClick={handleClick} />
          <NavBar handleClick={handleClick} open={open} />

          <div className="logo-principal">
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
