import { useEffect, useState } from "react";
import { fetchGalleries } from "../requests";
import GaleriaList from "./GaleriaList";
import HeaderComplementario from "./HeaderComplementario";

const GaleriaContainer = () => {
  const [pictures, setPictures] = useState([]);
  useEffect(() => {
    const getPictures = async () => {
      const pics = await fetchGalleries();
      setPictures(pics);
    };

    getPictures();
  }, []);

  return (
    <>
      <HeaderComplementario />

      <h1 className="works">Trabajos</h1>
      <div className="galeria-detail">
        <GaleriaList pictures={pictures} />
      </div>
    </>
  );
};

export default GaleriaContainer;
