import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const Galeria = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" onClick={() => setOpen(true)}>
        <div
          className="galeria-container"
          style={{ backgroundImage: `url(${item.fotos[0]})` }}
        >
          <p className="galeria-texto">{item.name}</p>
        </div>
      </button>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={item.fotos.map((item) => ({ src: item }))}
      />
    </>
  );
};

export default Galeria;
