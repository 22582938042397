import React, { Component } from "react";
import Slider from "react-slick";
import brand1 from "../assets/Images/brand1.png";
import brand2 from "../assets/Images/brand2.png";
import brand3 from "../assets/Images/brand3.png";
import brand4 from "../assets/Images/brand4.png";
import brand5 from "../assets/Images/brand5.png";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 1500,
      cssEase: "linear",
    };

    const brands = [brand1, brand2, brand3, brand4, brand5];
    return (
      <div className="container-brand wrapper">
        <Slider {...settings}>
          {brands.map((item, index) => (
            <div key={index}>
              <h3 className="brand">
                <img src={item} alt="" />
              </h3>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
