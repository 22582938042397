import logogaleria1 from "../assets/Images/galeria1.jpg";
import logogaleria2 from "../assets/Images/galeria2.jpg";
import logogaleria3 from "../assets/Images/galeria3.jpg";

import { Link } from "react-router-dom";
import CountUp from "react-countup";

const WhatWeDo = () => {
  return (
    <div>
      <div className="what-we-do-container wrapper">
        <h1>Nuestro trabajo en números</h1>

        <div className="work-numbers-container">
          <div>
            <p className="number">
              +
              <CountUp end={170} duration={10} />
            </p>
            <p className="phrase-increase">Cercos instalados</p>
          </div>
          <div>
            <p className="number">
              +
              <CountUp end={17} duration={2} />
            </p>
            <p className="phrase-increase">Barrios privados</p>
          </div>
          <div>
            <p className="number">
              +
              <CountUp end={97} duration={10} />%
            </p>
            <p className="phrase-increase">Efectividad ante robos</p>
          </div>
        </div>
      </div>

      <div className="galery-container wrapper">
        <div className="galery-detail">
          <img src={logogaleria1} alt="" />
          <div className="how-we-work-container">
            <p>Como trabajamos</p>
            <div className="underlined"></div>
            <Link to="/comotrabajamos" className="rutes-styles">
              ver mas
            </Link>
          </div>
        </div>
        <div className="galery-detail">
          <img src={logogaleria2} alt="" />
          <div className="how-we-work-container">
            <p>Garantía</p>
            <div className="underlined"></div>
            <Link to="/nosotros" className="rutes-styles">
              ver mas
            </Link>
          </div>
        </div>
        <div className="galery-detail">
          <img src={logogaleria3} alt="" />
          <div className="how-we-work-container">
            <p>Asesoramiento</p>
            <div className="underlined"></div>
            <a
              className="rutes-styles"
              href="https://api.whatsapp.com/send?phone=541121772352"
            >
              conectar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
