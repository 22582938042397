import { Link } from "react-router-dom";

const NavBar = ({ open, handleClick }) => {
  return (
    <div>
      <nav className={`nav-bar ${open ? "nav-bar-open" : ""}`}>
        {
          <button onClick={handleClick} className="close-nav">
            x
          </button>
        }
        <ul>
          <li className="sections">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="sections">
            <Link className="nav-link" to="/nosotros">
              Quienes somos
            </Link>
          </li>
          <li className="sections">
            <Link className="nav-link" to="/comotrabajamos">
              Como trabajamos
            </Link>
          </li>
          <li className="sections">
            <Link className="nav-link" to="/galeria">
              Galeria
            </Link>
          </li>
          <li className="sections">
            <Link className="nav-link" to="/videoseguridad">
              Videoseguridad
            </Link>
          </li>
          <li className="sections">
            <Link className="nav-link" to="/presupuesto">
              Contacto
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
