import HeaderComplementario from "./HeaderComplementario";

import logotrabajo from "../assets/Images/logo-comotrabajamos.png";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

const ComoTrabajamos = () => {
  return (
    <>
      <ScrollToTop />
      <HeaderComplementario />
      <div className="container-como-trabajamos">
        <div className="como-trabajamos-detail-left">
          <h1>Nuestro compromiso</h1>
          <h2>Como trabajamos</h2>
          <p>
            Instalación de un cerco eléctrico. La tecnología de los alambrados
            eléctricos no es nueva. Sigue siendo aún una herramienta de manejo,
            en permanente evolución, indispensable para la seguridad tanto del
            hogar, como de su empresa, o cualquier propiedad.
          </p>

          <p>
            Para lograr un rendimiento eficiente, vamos a comenzar desde el
            principio, desde la elección del electrificador y su instalación,
            hasta la construcción del alambrado, detallando los puntos críticos
            donde se pueden cometer los errores que no permitan lograr un máximo
            rendimiento.
          </p>
          <div className="responsive-text">
            <h2>Consideraciones previas</h2>
            <p>
              Antes de comprar el electrificador debemos planificar qué es lo
              que queremos hacer, con el fin de no cometer errores. En Cercos
              Electricos Security tenemos en cuenta:
            </p>
            <ul>
              <li>Distancia - Superficie - Subdivisiones a electrificar</li>
              <li>Tipos y duración de los alambrados</li>
              <li>Permanentes</li>
              <li>Sermipermanentes</li>
              <li>Transitorios</li>
              <li>Tipo de animales</li>
              <li>Zona - tipo de suelo</li>
            </ul>
          </div>
        </div>
        <div className="como-trabajamos-detail-rigth">
          <div className="detail-rigth-up">
            <div className="detail-rigth-text">
              <h2>El electrificador</h2>
              <p>
                Los nuevos electrificadores de alta performance vienen con
                variada gama de potencias. Cuanto mayor su potencia, mayor la
                "patada". Nuestro equipo lo guiará en la elección correcta del
                electrificador. Radio de acción
              </p>
              <h2>Radio de acción</h2>
              <p>
                Es la distancia entre el electrificador y el punto más lejano a
                electrificar. Puede ser o no en línea recta. Lo que indica este
                radio es la capacidad que tiene para electrificar. Se refiere a
                los kilómetros totales que puede electrificar en toda la red.
              </p>
            </div>
            <div className="logo-nice">
              <span>Nice</span>
            </div>
          </div>

          <div className="trabajo-img">
            <img src={logotrabajo} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ComoTrabajamos;
