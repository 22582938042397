import Slider from "./Slider";
import Carrousel from "./Carrousel";
import WhatWeDo from "./WhatWeDo";
import CarrouselBrand from "./CarrouselBrand";

const Main = ({}) => {
  return (
    <div>
      <Slider />
      <Carrousel />
      <WhatWeDo />
      <CarrouselBrand />
    </div>
  );
};

export default Main;
