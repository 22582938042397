import React from "react";
import HeaderComplementario from "./HeaderComplementario";
import seguridad from "../assets/Images/seguridad.jpeg";
import Footer from "./Footer";

const VideoSeguridad = () => {
  return (
    <div>
      <>
        <HeaderComplementario />
        <div className="container-seguridad">
          <h1>Video Seguridad & Alarmas</h1>
          <div className="seguridad-general">
            <div className="seguridad-detail-left">
              <div className="seguridad-group">
                <h2>
                  Video Seguridad <br /> Para Hogares
                </h2>
                <p>
                  En la actualidad los sistemas de video vigilancia con cámaras
                  de seguridad para casas ya no son exclusivos de las grandes
                  empresas ni de los organismos del estado. Con el avance de la
                  tecnología asociada a la seguridad electrónica hoy es posible
                  contar con un sistema profesional de cámaras de seguridad para
                  hogares. A raíz de esto, muchos fabricantes de equipos de
                  seguridad electrónica, cuentan con líneas de productos
                  orientadas a usuarios hogareños.
                </p>
              </div>
              <div className="seguridad-group">
                <h2>Para empresas</h2>

                <p>
                  Hoy en día las cámaras de seguridad para empresas dejaron de
                  ser un accesorio electrónico, son algo fundamental y necesario
                  para el correcto funcionamiento de cualquier empresa o
                  compañía. La instalación de cámaras de seguridad para empresas
                  trae beneficios como mejorar notablemente la seguridad de la
                  empresa ante hechos delictivos, aumento de la productividad de
                  los empleados y operarios, control de zonas de acceso
                  restringido o prohibido entre otros.
                </p>
              </div>
              <button className="seguridad-button">
                <a href="https://api.whatsapp.com/send?phone=541121772352">
                  Escribinos <br />o llamanos
                </a>
              </button>
            </div>
            <div className="seguridad-detail-rigth">
              <div className="detail-rigth-text-seguridad">
                <div className="seguridad-group">
                  <h2>Alarmas para hogares</h2>
                  <p>
                    En la actualidad los sistemas de video vigilancia con
                    cámaras de seguridad para casas ya no son exclusivos de las
                    grandes empresas ni de los organismos del estado. Con el
                    avance de la tecnología asociada a la seguridad electrónica
                    hoy es posible contar con un sistema profesional de cámaras
                    de seguridad para hogares. A raíz de esto. muchos
                    fabricantes de equipos de seguridad electrónica, cuentan con
                    líneas de productos orientadas a usuarios hogareños.
                  </p>
                </div>
                <div className="seguridad-group">
                  <h2>Para empresas</h2>
                  <p>
                    Hoy en día las cámaras de seguridad para empresas dejaron de
                    ser un accesorio electrónico, son algo fundamental y
                    necesario para el correcto funcionamiento de cualquier
                    empresa o compañía. La instalación de cámaras de seguridad
                    para empresas trae beneficios como mejorar notablemente la
                    seguridad de la empresa ante hechos delictivos, aumento de
                    la productividad de los empleados y operarios, control de
                    zonas de acceso restringido o prohibido entre otros.
                  </p>
                </div>
              </div>

              <div className="videoseguridad-img">
                <img src={seguridad} alt="" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    </div>
  );
};

export default VideoSeguridad;
