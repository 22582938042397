import React from "react";

import primera from "../assets/Images/primera.png";
import segunda from "../assets/Images/segunda.png";
import cuarta from "../assets/Images/cuarta.png";
import tercera from "../assets/Images/tercera.png";
import quinta from "../assets/Images/quinta.png";

const Carrousel = () => {
  const carrouselItems = [
    {
      nombre: "Sin riesgo de muerte",
      imagen: primera,
    },
    {
      nombre: "Respaldo técnico",
      imagen: segunda,
    },
    {
      nombre: "Batería de respaldo",
      imagen: tercera,
    },
    {
      nombre: "Equipos certificados",
      imagen: cuarta,
    },
    {
      nombre: "Sensasión disuasiva",
      imagen: quinta,
    },
  ];
  return (
    <div className="pros-cercos">
      <p className="description">
        Tenemos a su disposición las más grande variedad de artículos para la{" "}
        <br /> la instalación de sistema de seguridad para proteger{" "}
        <strong>tu casa o empresa.</strong>{" "}
      </p>
      <div className="container-carrousel wrapper">
        {carrouselItems.map((item) => (
          <div key={item.nombre} className="carousel-detail">
            <img className="carrousel-img" src={item.imagen} alt="" />
            <h1>{item.nombre}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carrousel;
