import Icon from "./Icon";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/Images/cerco-sequrity-logo.png";
import { Link } from "react-router-dom";
import hglogo from "../assets/Images/ladran_sancho.png";

const Footer = () => {
  return (
    <div className="wrapper">
      <div className="linea-divisoria-footer wrapper"></div>
      <div className="container-footer wrapper">
        <div className="design-page">
          <a href="https://hgstudio.com.ar/" target="_blank">
            <img className="logo-hg" src={hglogo} alt="" />
          </a>

          <p>diseño</p>
        </div>
        <div className="separacion-dos"></div>
        <div className="container-logo-mail">
          <div className="separacion-icon-mail">
            <Icon css="icon" icon={faEnvelope} />
            <p>info@cercoelectricosecurity.com</p>
          </div>
          <div>
            <Link to="/">
              <img className="logo-footer" src={logo} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
