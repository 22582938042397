import logogaleria1 from "../assets/Images/galeria1.jpg";
import HeaderComplementario from "./HeaderComplementario";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

const Nosotros = () => {
  return (
    <>
      <ScrollToTop />
      <div className="screen">
        <HeaderComplementario />

        <div className="container-nosotros">
          <div className="galeria-text">
            <h1>Nosotros</h1>
            <p>
              Cerco Eléctrico Security es una empresa dedicada a la
              construcción, instalación y mantenimiento de cercos eléctricos
              perimetrales de seguridad. Nuestro principal interés está enfocado
              en la protección de las vidas y el resguardo de los bienes de cada
              uno de nuestros clientes, cubriendo así todas las expectativas y
              las necesidades que presenten los mismos, utilizando para ello los
              más avanzados sistemas de seguridad electrónica y contando con un
              excelente equipo de trabajo comprometido y capacitado.
            </p>
            <p>
              Somos LÍDERES en SEGURIDAD PERIMETRAL DE CASAS, EDIFICIOS,
              EMPRESAS. Estamos especializados en la seguridad perimetral,
              contamos con accesorios y equipos de alta calidad.
            </p>
            <p>
              Prestamos servicios particulares, comunidades de vecinos,
              compañías de seguro, cooperativas, fábricas, escuelas, empresas,
              universidades, consulados, embajadas, etc.
            </p>
            <p>
              En Cerco Eléctrico Security buscamos definir y mejorar estándares
              de calidad y protección, contribuyendo de manera efectiva a
              mejorar la calidad de vida de nuestros clientes. Destacamos como
              valores la responsabilidad, creatividad, innovación y compromiso.
            </p>
            <div className="nosotros-button">
              <a
                href="https://api.whatsapp.com/send?phone=541121772352"
                className="detail-button"
              >
                Hablemos <br /> de seguridad
              </a>
            </div>
          </div>
          <div>
            <img src={logogaleria1} alt="" />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Nosotros;
