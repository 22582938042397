import logo from "../assets/Images/cerco-sequrity-logo.png";
import { Link } from "react-router-dom";
import x from "../assets/Images/x.png";

const HeaderComplementario = () => {
  return (
    <div className="header-two-container wrapper">
      <div className="complementario"></div>
      <div className="details-header-complementario">
        <Link to="/">
          <img className="logo-complementario" src={logo} alt="" />
        </Link>

        <Link to="/">
          <img className="logo-close" src={x} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default HeaderComplementario;
